<template>
  <div>
  <v-dialog
      v-model="activo"
      width="850"
      :persistent="activo"
      scrollable
    >
      <v-card>
        <v-card-title class="">
          <div style="font-size: 20px">Vigencia de financiaciones</div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row class="pt-2">
            <v-col cols="12" class="py-1">
              <v-row>
                <v-col cols="8" sm="6" md="3">
                  Nueva Fecha Hasta
                  <FechaPickerVue v-model="fecha"
                    hideDetails
                    outline clearable/>
                </v-col>
                <v-col cols="12" sm="6" md="9" class="d-flex justify-end align-center">
                  <div :class="pantallaChica ? 'd-flex ' : ''">
                    <v-btn
                      class="mr-2"
                      color="secondary"
                      @click="activo = false">
                      cancelar
                    </v-btn>
                    <BtnConfirmarVue
                      :texto="`Estás por cambiar la vigencia de estas financiaciones. ¿Confirma esta accion?`"
                      :nombre="'GUARDAR CAMBIOS'"
                      :loading="load"
                      @action="guardar()"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="header"
                :items="financiaciones"
                sort-by="hasta"
                sort-desc
                dense
              >
                <!-- Fecha desde -->
                <template v-slot:[`item.desde`]="{item}">
                  {{ item.desde ? moment(item.desde).format("DD/MM/YYYY") : '' }}
                </template>
                <!-- Fecha hasta --> 
                <template v-slot:[`item.hasta`]="{item}">
                  {{ item.hasta ? moment(item.hasta).format("DD/MM/YYYY") : '' }}
                </template>
                <!-- No hay datos -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No hay financiaciones para modificar
                  </v-alert>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import BtnConfirmarVue from '../util/BtnConfirmar.vue';
  import FechaPickerVue from '../util/FechaPicker.vue';
  
  export default {
    name: 'ModalVigenciaFinanciaciones',
    props:{
      value: Boolean,
      financiaciones: Array
    },
    computed:{
      activo:{
        get(){
          return this.value;
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    data(){
      return{
        moment: moment,
        load: false,
        fecha: moment(new Date()).format('DD/MM/YYYY'),
        header:[
          {text: 'Código', value: 'id'},
          {text: 'Descripción', value: 'descripcion'},
          {text: 'Desde', value: 'desde'},
          {text: 'Hasta', value: 'hasta'},
        ],
        pantallaChica: this.$vuetify.breakpoint.xs,
      }
    },
    created(){
    },
    methods:{
      async init(){
  
      },
      async guardar(){
        if(this.financiaciones.length <= 0){
          return this.$store.dispatch('show_snackbar', {
            text: 'No se seleccionó ninguna financiación. No es posible continuar.',
            color: 'info'
          })
        }
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('financiaciones/updateVigenciaMasiva', { financiaciones: this.financiaciones, vigencia: this.fecha });
        this.$store.state.loading = false;
        console.log("res: ", res);

        //errores ajenos al código, inconsistencias, etc...
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          });
        }
        // no es error pero se informa que hubo algunas que no se pudieron cambiar la vigencia
        if(res.resultado == 2){
          return this.$store.dispatch('show_snackbar', {
            text: res.f_no_actualizadas.slice(2),
            color: 'info'
          });
        }
        // exitoso
        this.$swal.fire({
          icon: 'success',
          title: 'Financiaciones actualizadas',
          text: `Se cambiaron correctamente las vigencias de las financiaciones.`,
        });
      
        this.activo = false;
        this.$emit('actualizar');
      },
    },
    components:{
      FechaPickerVue, BtnConfirmarVue
    },
    watch:{
      activo: function(val){
        if(!val){
          this.fecha = moment(new Date()).format('DD/MM/YYYY'); 
        }
      },
      '$vuetify.breakpoint.xs'(val){
        this.pantallaChica = val;
      },
    }
  }
  </script>
  
  <style>
  
  </style>